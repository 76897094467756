<template>
  <v-container class="px-5 px-md-16">
    <v-container v-if="selectedInfo == ''" class="py-15 my-15">
      <div class="py-15 text-center my-15">
        <mini-loader class="text-center"></mini-loader>
      </div>
    </v-container>
    <div v-else>
      <v-card elevation="0" class="rounded-lg blue lighten-5 pa-10">
        <div class=" mb-8">
          <div class="text-h5 secondary--text font-weight-medium">{{ selectedInfo.name | capitalize }}</div>
          <div class="text-subtitle-1">{{ selectedInfo.description}}</div>
        </div>
        <v-row align="center" justify="center">
          <v-col cols="12" md="3">
            <div class="text-body-2">Savings Amount</div>
            <div class="primary--text text-h5 font-weight-medium">&#8358; {{ selectedInfo.amount  | formatPrice}}</div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="text-body-2">Total Balance</div>
            <div class="primary--text text-h5 font-weight-medium">&#8358; {{ selectedInfo.balance  | formatPrice}}</div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="text-body-2">Interest</div>
            <div class="primary--text text-h5 font-weight-medium">{{ selectedInfo.interest_rate * 100}}&#37;</div>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn color="secondary" large depressed @click="topUpDialog = true" v-show="showTopUp()">Top Up Savings</v-btn>
            <v-btn color="primary" large depressed v-show="!searchedMember && selectedInfo.type != 'Personal' && selectedInfo.type != 'Fixed'" @click="joinGroup(selectedSavingsId)">Join Group</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="mt-2" v-show="selectedInfo.type == 'Personal' || selectedInfo.type == 'Fixed'">
        <v-col cols="3">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(transfersavings = true)">
            <v-icon color="secondary">mdi-bank-transfer</v-icon>
            <div class="text-caption text-center">Transfer</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(extendsavings = true)">
            <v-icon color="secondary">mdi-calendar</v-icon>
            <div class="text-caption text-center">Extend</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(quitsavings = true)">
            <v-icon color="secondary">mdi-exit-run</v-icon>
            <div class="text-caption text-center">Quit</div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(settings = true)">
            <v-icon color="secondary">mdi-cog</v-icon>
            <div class="text-caption text-center">Settings</div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2" v-show="selectedInfo.type == 'Group' && searchedMember">
        <v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(transfersavings = true)">
            <v-icon color="secondary">mdi-bank-transfer</v-icon>
            <div class="text-caption text-center">Transfer</div>
          </div>
        </v-col>
        <v-col cols="4" v-if="selectedInfo.userId == user.id">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(joinrequests = true), getGroupRequests()">
            <v-icon color="secondary">mdi-inbox-arrow-down</v-icon>
            <div class="text-caption text-center">Requests</div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(groupmembers = true), getGroupMembers()">
            <v-icon color="secondary">mdi-account-group</v-icon>
            <div class="text-caption text-center">Members</div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(groupvote = true)">
            <v-icon color="secondary">mdi-vote</v-icon>
            <div class="text-caption text-center">Vote</div>
          </div>
        </v-col>
        <!--v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(inviteDialog = true)">
            <v-icon color="secondary">mdi-account-arrow-right</v-icon>
            <div class="text-caption text-center">Invite friend</div>
          </div>
        </v-col-->
        <v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(settings = true)">
            <v-icon color="secondary">mdi-cog</v-icon>
            <div class="text-caption text-center">Settings</div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(quitsavings = true)">
            <v-icon color="secondary">mdi-exit-run</v-icon>
            <div class="text-caption text-center">Quit</div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2" v-show="selectedInfo.type == 'Agent' && searchedMember">
        <v-col cols="4" v-if="selectedInfo.userId == user.id">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(agentjoinrequests = true),  getAgentRequests(selectedInfo.id)">
            <v-icon color="secondary">mdi-inbox-arrow-down</v-icon>
            <div class="text-caption text-center">Requests</div>
          </div>
        </v-col>
        <!--v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(groupvote = true)">
            <v-icon color="secondary">mdi-vote</v-icon>
            <div class="text-caption text-center">Vote</div>
          </div>
        </v-col-->
        <v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(commission = true)">
            <v-icon color="secondary">mdi-percent</v-icon>
            <div class="text-caption text-center">Commission</div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(agentgroupmembers = true), getAgentGroupMembers(selectedInfo.id)">
            <v-icon color="secondary">mdi-account-group</v-icon>
            <div class="text-caption text-center">Members</div>
          </div>
        </v-col>
        <!--v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(inviteDialog = true)">
            <v-icon color="secondary">mdi-account-arrow-right</v-icon>
            <div class="text-caption text-center">Invite friend</div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(settings = true)">
            <v-icon color="secondary">mdi-cog</v-icon>
            <div class="text-caption text-center">Settings</div>
          </div>
        </v-col>
        <v-col cols="4" v-if="selectedInfo.userId != user.id">
          <div class="text-caption outl actin_cursr rounded py-2 text-center" @click="(quitsavings = true)">
            <v-icon color="secondary">mdi-exit-run</v-icon>
            <div class="text-caption text-center">Quit</div>
          </div>
        </v-col-->
      </v-row>
      <v-row class="mt-10 mx-0">
        <v-col cols="12" md="6">
          <v-card outlined class="grey lighten-5 pa-5 rounded-lg">
            <!--v-col class="" cols="12" md="6">
              <div class="text-body-2">Savings Preference</div>
              <div class="primary--text text-h5 font-weight-medium">&#8358;{{ selectedInfo.projected_saving}}/<span class="secondary--text text-body-1">{{ selectedInfo.cycle}}</span></div>
            </v-col-->
            <v-col cols="12" md="12">
              <div class="text-body-2 mb-3">Savings Progress</div>
              <div><v-progress-linear :value="savings_progress" color="secondary" height="5"></v-progress-linear></div>
            </v-col>
          </v-card>
          <v-card outlined class="pa-5 mt-10">
            <div class="text-h6 font-weight-medium primary--text">Savings Details</div>
            <v-divider class="my-2"></v-divider>
            <v-row class="mt-5">
              <v-col cols="6">
                <div class="text--secondary text-body-2">Start date</div>
                <div class="text-body-1 font-weight-medium primary--text">{{ selectedInfo.start_save | formatDate}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text--secondary text-body-2">Maturity date</div>
                <div class="text-body-1 font-weight-medium primary--text">{{ selectedInfo.end_date | formatDate}}</div>
              </v-col>
              <v-col cols="6">
                <div class="text--secondary text-body-1">Duration</div>
              </v-col>
              <v-col cols="6">
                <div class="text-body-1 font-weight-medium primary--text">{{ selectedInfo.duration }} Month<span v-show="selectedInfo.duration != '1'">s</span></div>
              </v-col>
              <v-col cols="6">
                <div class="text--secondary text-body-1">Savings Type</div>
              </v-col>
              <v-col cols="6">
                <div class="text-body-1 font-weight-medium primary--text">One Time {{ selectedInfo.type | capitalize }} Savings</div>
              </v-col>
              <!--v-col cols="6">
                <div class="text--secondary text-body-1">Next savings date</div>
              </v-col>
              <v-col cols="6">
                <v-btn class="blue lighten-5" text><div class="text-body-1 font-weight-medium primary--text">{{ selectedInfo.next_save | formatDate}}</div></v-btn>
              </v-col-->
              <v-col cols="6">
                <div class="text--secondary text-body-1">Days Left</div>
              </v-col>
              <v-col cols="6">
                <v-btn class="blue lighten-5" text><div class="text-body-1 font-weight-medium primary--text">{{ days_left }} Day<span v-show="days_left != '1'">s</span></div></v-btn>
              </v-col>
              <v-col cols="6">
                <div class="text--secondary text-body-1">Date Created</div>
              </v-col>
              <v-col cols="6">
                <div class="text-body-1 font-weight-medium primary--text">{{ selectedInfo.created_at | formatDate}}</div>
              </v-col>
              <v-col cols="6" v-show="showTopUp()">
                <div class="text--secondary text-body-1">Auto Save</div>
              </v-col>
              <v-col cols="6" v-show="showTopUp()">
                <v-switch class="pa-0 ma-0" inset dense></v-switch>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card outlined class="mt-0">
            <div class="fill-height py-16 rounded-lg text-center white text--secondary">
              <div class="py-16">You have no transactions yet</div>
            </div>
          </v-card>
          <div class="text-center"><v-btn class="primary mt-5" depressed link :to="{ name: 'Savings' }">Back to Savings</v-btn></div>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="groupmembers" width="500">
      <v-card class="pa-6 px-5 px-md-10 form-colored-bg">
        <div class="mb-5">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Group Members {{ selectedGroupMembers.length }}</div>
          <div class="text-body-2 secondary--text">{{ selectedInfo.name | capitalize }}</div>
          <div class="text-body-2 text--secondary">Most recent members paricipating in this savings challenge</div>
        </div>
        <template v-if="selectedGroupMembers != ''" >
          <v-list nav class="grey rounded-lg bene px-3 lighten-5" two-lines>
            <template v-for="(member, index) in selectedGroupMembers">
              <v-list-item  class="rounded-lg white mb-2" link :key="index" @click="(selectUser = true), (selectedUser = member)" >
                  <v-list-item-avatar>
                    <img alt="" :src="member.image || require('@/assets/icons/user.png')"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="primary--text" v-text="member.name"></v-list-item-title>
                    <v-list-item-subtitle><span class="secondary--text text-caption font-weight-medium"></span><span v-show="member.admin == 1" class="secondary--text">Admin</span> <span v-show="member.admin != 1" class="secondary--text">Member</span></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-title class="primary--text text-subtitle-1">&#8358; {{ member.balance | formatPrice}}</v-list-item-title>
                  </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <v-btn outlined block class="primary mt-10" large dark @click="(groupHistory = true), getGroupSavingsHistory()">Savings history</v-btn>
          <v-btn block class="mt-2" depressed large @click="groupmembers = !groupmembers">Dismiss</v-btn>
        </template>

        <div v-else>
          <div class="fill-height py-16 rounded-lg text-center white text--secondary">
            <div>No member in this savings</div>
            <v-btn outlined class="primary mt-3" dark @click="inviteDialog = true">Invite friend</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="groupHistory" width="800">
      <v-card class="pa-6 px-5 px-md-10 form-colored-bg">
        <div class="mb-5">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Members History</div>
          <div class="text-body-2 secondary--text">{{ selectedInfo.name | capitalize }}</div>
        </div>
        <template v-if="selectedGroupMembers != ''" >
          <v-data-table
            :headers="headers"
            :items="selectedSavingsHistory"
            :loading="loading"
            loading-text="Loading data... Please wait"
          >
            <template v-slot:[`header.name`]="{ header }">
              {{ header.text.toUpperCase() }}
            </template>
          </v-data-table>
          <div class="text-center"><v-btn class="mt-2" depressed large @click="groupHistory = !groupHistory">Dismiss</v-btn></div>
        </template>

        <div v-else>
          <div class="fill-height py-16 rounded-lg text-center white text--secondary">
            <div>No member in this savings</div>
            <v-btn outlined class="primary mt-3" dark @click="inviteDialog = true">Invite friend</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="agentgroupmembers" width="500">
      <v-card class="pa-6 px-5 px-md-10 form-colored-bg">
        <div class="mb-5">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Group Members {{ selectedGroupMembers.length }}</div>
          <div class="text-body-2 secondary--text">{{ selectedInfo.name | capitalize }}</div>
          <div class="text-body-2 text--secondary">Most recent members paricipating in this savings challenge</div>
        </div>
        <template v-if="selectedGroupMembers != ''" >
          <v-list nav class="grey rounded-lg bene px-3 lighten-5" two-lines>
            <template v-for="(member, index) in selectedGroupMembers">
              <v-list-item  class="rounded-lg white mb-2" link :key="index" @click="(selectUser = true), (selectedUser = member)" >
                  <v-list-item-avatar>
                    <img alt="" :src="member.image || require('@/assets/icons/user.png')"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="primary--text" v-text="member.name"></v-list-item-title>
                    <v-list-item-subtitle><span class="secondary--text text-caption font-weight-medium"></span><span v-show="member.admin == 1" class="secondary--text">Admin</span> <span v-show="member.admin != 1" class="secondary--text">Member</span></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-title class="primary--text text-subtitle-1">&#8358; {{ member.balance | formatPrice}}</v-list-item-title>
                  </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <v-btn outlined block class="primary mt-10" large dark @click="getAgentSavingsHistory(selectedInfo.id)">Savings history</v-btn>
          <v-btn block class="mt-2" depressed large @click="agentgroupmembers = !agentgroupmembers">Dismiss</v-btn>
        </template>

        <div v-else>
          <div class="fill-height py-16 rounded-lg text-center white text--secondary">
            <div>No member in this savings</div>
            <v-btn outlined class="primary mt-3" dark @click="inviteDialog = true">Invite friend</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="joinrequests" width="800">
      <v-card class="pa-6 px-8 form-colored-bg">
        <div class="mb-5">
          <v-row>
            <v-col>
              <div class="mb-2 primary--text text-h5 font-weight-medium">Member Requests</div>
            </v-col>
            <v-col class="text-right" v-if="grouprequests != ''">
              <v-btn depressed small>clear List</v-btn>
            </v-col>
          </v-row>          
        </div>
        <v-divider class="m-5"></v-divider>
        <template v-if="grouprequests != ''" >
          <v-simple-table height="400px">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(request, req) in grouprequests" :key="req"
                >
                  <td>{{ request.name | capitalize }}</td>
                  <td class="text-center">{{ request.created_at | formatDate }}</td>
                  <td class="text-right"><v-btn depressed class="primary" small @click="accept(request.user_id, request.group_id, req)">Accept</v-btn> <v-btn class="red floatright white--text" @click="reject(request.user_id, request.group_id, req)" depressed small>Reject</v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

        <div v-else>
          <div class="fill-height py-16 rounded-lg text-center white text--secondary">
            <div>No group requests available</div>
          </div>
        </div>
        <v-btn large block class="mt-10" @click="joinrequests = !joinrequests" depressed>Dismiss</v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="agentjoinrequests" width="800">
      <v-card class="pa-6 px-8 form-colored-bg">
        <div class="mb-5">
          <v-row>
            <v-col>
              <div class="mb-2 primary--text text-h5 font-weight-medium">Member Requests</div>
            </v-col>
            <v-col class="text-right" v-if="grouprequests != ''">
              <v-btn depressed small>clear List</v-btn>
            </v-col>
          </v-row>          
        </div>
        <v-divider class="m-5"></v-divider>
        <template v-if="grouprequests != ''" >
          <v-simple-table height="400px">
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(request, req) in grouprequests" :key="req"
                >
                  <td>{{ request.name | capitalize }}</td>
                  <td class="text-center">{{ request.created_at | formatDate }}</td>
                  <td class="text-right"><v-btn depressed class="primary" small @click="acceptagentjoin(request.user_id, request.group_id, req)">Accept</v-btn> <v-btn class="red floatright white--text" @click="rejectagentjoin(request.user_id, request.group_id, req)" depressed small>Reject</v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>

        <div v-else>
          <div class="fill-height py-16 rounded-lg text-center white text--secondary">
            <div>No group requests available</div>
          </div>
        </div>
        <v-btn large block class="mt-10" @click="agentjoinrequests = !agentjoinrequests" depressed>Dismiss</v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="groupvote" width="500">
      <v-card class="pa-6 px-12">
        <div class="mb-5">
          <div class="mb-2 primary--text text-h5 font-weight-medium" v-if="!tovote">Vote</div>
          <div class="mb-2 primary--text text-h5 font-weight-medium" v-if="tovote == 'admin'">Vote for admin</div>
          <div class="mb-2 primary--text text-h5 font-weight-medium" v-if="tovote == 'disburse'">Vote next fund recipient</div>
          <div class="mb-2 primary--text text-h5 font-weight-medium" v-if="tovote == 'result'">Voting results</div>
          <div class="text-body-2 text--secondary" v-if="!tovote">Select and proceed to vote for next admin or fund recipient.</div>
          <div class="text-body-2 text--secondary" v-if="tovote == 'admin'">Vote by selecting your choice candidate on the radio button to make and admin, you can only vote once.</div>
          <div class="text-body-2 text--secondary" v-if="tovote == 'disburse'">Vote by selecting your choice candidate on the radio button to choose next fund recipient, you can only vote once.</div>
          <div class="text-body-2 text--secondary" v-if="tovote == 'result'">Total group members that participated in last vote {{ disbursementOutcome.length }}.</div>      
        </div>
        <div class="mt-10" v-if="!tovote">
          <v-btn block color="primary" outlined large class="mb-3" @click="(tovote = 'admin')">Vote for admin</v-btn>
          <v-btn block color="primary" outlined large class="mb-3" @click="(tovote = 'disburse')">Vote to disburse</v-btn>
          <v-btn block color="primary" large @click="(tovote = 'result'); getDisbursementVoteOutcome()">View disbursement results</v-btn>
          <v-btn block class="mt-10" depressed large @click="groupvote = !groupvote">Dismiss</v-btn>
        </div>
        <template v-if="tovote == 'admin' && selectedGroupMembers != ''">
          <v-form ref="vote">
            <v-radio-group v-model="votemember">
              <v-radio class="vote_ pa-3" v-for="(n, index) in selectedGroupMembers" :key="index" :label="n.name" :value="n.member_id"></v-radio>
            </v-radio-group>
            <v-btn block class="primary mt-15" dark @click="$refs.vote.validate() ? voteAdmin() : null" :loading="loading" large>Vote</v-btn>
            <v-btn block light @click="tovote = !tovote" depressed class="mt-3">Cancel</v-btn>
          </v-form>
        </template>

        <template v-if="tovote == 'disburse' && selectedGroupMembers != ''">
          <v-form ref="disburse">
            <v-radio-group v-model="votemember">
              <v-radio class="vote_ pa-3" v-for="(n, index) in selectedGroupMembers" :key="index" :label="n.name" :value="n.member_id"></v-radio>
            </v-radio-group>
            <v-btn block class="primary mt-15" dark @click="$refs.disburse.validate() ? voteDisburse(selectedInfo.id) : null" :loading="loading"  depressed large>Vote</v-btn>
            <v-btn block light @click="tovote = !tovote" depressed class="mt-3">Cancel</v-btn>
          </v-form>
        </template>
        <template v-if="tovote == 'result' && selectedGroupMembers != ''">
          <div class="py-15 text-center my-15" v-if="disbursementOutcome == ''">
            <mini-loader class="text-center"></mini-loader>
          </div>
          <v-list nav class="bene lighten-5" v-else>
            <template v-for="(member, index) in disbursementOutcome">
              <v-list-item  class="rounded-lg blue lighten-5 px-4 mb-2" :key="index">
                <v-list-item-content>
                  <v-list-item-title class="primary--text" v-text="member.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-title class="secondary--text font-weight-medium text-subtitle-1">{{ member.votes}} vote<span v-show="member.votes > 1">s</span></v-list-item-title>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-btn block light @click="tovote = !tovote" depressed class="mt-3">Dismiss</v-btn>
          </v-list>
          
        </template>

        <div v-if="tovote == 'admin' && selectedGroupMembers == ''">
          <div class="fill-height py-16 rounded-lg text-center white text--secondary">
            <div>No group members to vote</div>
            <v-btn outlined class="primary mt-3" dark depressed @click="tovote = !tovote">Cancel</v-btn>
          </div>
        </div>

        <div v-if="tovote == 'disburse' && selectedGroupMembers == '' ">
          <div class="fill-height py-16 rounded-lg text-center white text--secondary">
            <div>No group members to vote</div>
            <v-btn outlined class="primary mt-3" depressed dark @click="tovote = !tovote">Cancel</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="transfersavings" width="500">
      <v-card class="pa-6 px-12 form-colored-bg">
        <div class="">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Transfer Savings</div>
          <div class="text-body-2 text--secondary">Withdraw your savings by transfering to your bank account or wallet</div>
        </div>
        <v-form class="mt-6" lazy-validation v-model="valid" ref="withdrawal">

          <div class="text-h6 primary--text font-weight-normal">Select withdrawal destination</div>
          <v-select outlined v-model="form.withdraw_wallet" required :rules="[rules.required]" :items="['Transave Wallet']"></v-select>

          <div class="text-h6 primary--text font-weight-normal">Enter amount</div>
          <v-text-field prefix="₦" v-model="form.amount" outlined required :rules="[rules.required, rules.min]"></v-text-field>

          <div class="text-h6 primary--text font-weight-normal">Enter Transaction PIN</div>
          <v-text-field v-model="form.transaction_pin" outlined required :rules="[rules.required]"></v-text-field>

          <v-btn class="primary" :loading="loading" @click="$refs.withdrawal.validate() ? withdrawSavings() : null" block x-large>Withdraw</v-btn>
          <v-btn class="mt-3" depressed large @click="transfersavings = !transfersavings" block>Cancel</v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="extendsavings" width="500">
      <v-card class="pa-6 px-12 form-colored-bg">
        <div class="">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Extend savings | {{ selectedInfo.name | capitalize }}</div>
          <div class="text-body-2 text--secondary">You can now extend your target amount, timeline and savings amount</div>
        </div>
        <v-form class="mt-6" lazy-validation v-model="valid" ref="extend">
          <div class="text-h6 primary--text font-weight-normal">Change Savings Amount</div>
          <v-text-field
            prefix="₦"
            v-model="form.amount"
            outlined
            required
            :rules="[rules.required, rules.ext_min]"
          ></v-text-field>
          
          <div class="text-h6 primary--text font-weight-normal">Change Savings Frequency</div>
          <v-select outlined v-model="form.cycle" required :rules="[rules.required]" :items="['Daily', 'Weekly', 'Monthly']"></v-select>
          
          <div class="text-h6 primary--text font-weight-normal">Change Savings Duration</div>
          <v-select outlined v-model="form.duration" required :rules="[rules.required]"
            :items="[
               // { text: '1 Month', value: 1 },
               // { text: '2 Months', value: 2 },
                { text: '3 Months - 4% Interest', value: 3 },
               // { text: '4 Months', value: 4 },
               // { text: '5 Months', value: 5 },
                { text: '6 Months - 8% Interest', value: 6 },
               // { text: '7 Months', value: 7 },
              //  { text: '8 Months', value: 8 },
                { text: '9 Months - 12% Interest', value: 9 },
              //  { text: '10 Months', value: 10 },
              //  { text: '11 Months', value: 11 },
                { text: '12 Months - 16% Interest', value: 12 },
              ]"
          ></v-select>
          
          <div class="text-h6 primary--text font-weight-normal">Increase {{ selectedInfo.cycle }} Target Amount to:</div>
          <v-text-field readonly prefix="₦" v-model="overallAmount" outlined required :rules="[rules.required]"></v-text-field>
          
          <div class="text-h6 primary--text font-weight-normal">Enter Transaction PIN</div>
          <v-text-field v-model="form.pin" outlined type="password" required :rules="[rules.required]"></v-text-field>
          
          <v-btn class="primary" :loading="loading" @click="$refs.extend.validate() ? extendSavings() : null" block x-large>Submit</v-btn>
          <v-btn class="mt-3" depressed large @click="extendsavings = !extendsavings" block>Cancel</v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="quitsavings" width="500">
      <v-card class="pa-6 px-12 form-colored-bg">
        <div class="">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Quit savings | {{ selectedInfo.name | capitalize }}</div>
          <div class="text-body-2 text--secondary">Breaking or quiting savings before due time will attract a 1% deduction from your total savings.</div>
        </div>
        <v-form class="mt-6" lazy-validation v-model="valid" ref="quit">
          <div class="text-h6 primary--text font-weight-normal">Reason for breaking out</div>
          <v-select outlined v-model="form.reason" required :rules="[rules.required]" :items="['I have an emergency']"></v-select>          
          
          <div class="text-h6 primary--text font-weight-normal">Tell us why you're breaking</div>
          <v-text-field v-model="form.explanation" outlined placeholder="I have an emergency" required :rules="[rules.required]"></v-text-field>
          
          <div class="text-h6 primary--text font-weight-normal">Select withdrawal destination</div>
          <v-select outlined v-model="form.destination" required :rules="[rules.required]" :items="['Transave Wallet']"></v-select>

          <div class="text-h6 primary--text font-weight-normal">Enter transaction PIN</div>
          <v-text-field type="password" v-model="form.transaction_pin" outlined required :rules="[rules.required]"></v-text-field>

          <v-btn class="primary" :loading="loading" @click="$refs.quit.validate() ? quittarget() : null" block x-large>Submit</v-btn>
          <v-btn @click="(quitsavings = !quitsavings), (loading = false)" light block large class="mt-3" depressed>Cancel</v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="topUpDialog" width="500">
      <v-card class="pa-6 px-12 form-colored-bg">
        <div class="mb-2 primary--text text-h5 font-weight-medium">Instant Top Up</div>
        <div class="text-body-2 text--secondary">We made it simpler, if you break this target before the withdrawal date, 
            you will loose all the interest accrued and bear the 1% payment gateway charge for processing your deposits into this target.</div>
        <v-form class="mt-6" lazy-validation v-model="valid" ref="topup">
          <div class="text-h6 primary--text font-weight-normal">Enter Amount</div>
          <v-text-field
            prefix="₦"
            v-model="selectedInfo.projected_saving"
            outlined readonly
            required
            :rules="[rules.required]"
          ></v-text-field>
          <div class="text-h6 primary--text font-weight-normal">Source of Funding</div>
          <v-select
            outlined
            v-model="form.source"
            required
            :rules="[(v) => !!v || 'Please select source of funding']"
            :items="['Card', 'Wallet']"
          ></v-select>
          <v-btn
            class="primary"
            :loading="loading"
            @click="$refs.topup.validate() && form.source == 'Card' ? clickPayStack()
              : $refs.topup.validate() && form.source == 'Wallet' ? topUpSavingsWallet()
              : null
            "
            block
            x-large
            >Submit</v-btn
          >
          <v-btn @click="topUpDialog = !topUpDialog; loading = false" block large light class="mt-3" depressed>Cancel</v-btn
          >
        </v-form>
        <paystack
          :amount="parseInt(selectedInfo.projected_saving) * 100"
          :email="user.email"
          :paystackkey="paystackkey"
          :reference="reference"
          :callback="callback"
          :close="close"
          :embed="false"
          id="paystack"
          style="visibility: hidden"
        >
          <i class="fas fa-money-bill-alt"></i> Make Payment
        </paystack>
      </v-card>
    </v-dialog>
    <v-dialog v-model="inviteDialog" width="600">
      <v-card class="pa-6 px-12 form-colored-bg">
        <div class="">
          <div class="mb-2 primary--text text-h5 font-weight-medium">Invite Friends</div>
          <div class="text-body-2 text--secondary">Refer To Target, Refer friends to this savings challenge. Friends
            and family who join this group should have an acount on transave and
            are willing to participate in the challenge</div>
        </div>
        <v-form class="mt-8" lazy-validation v-model="valid" ref="invite">
          <div class="text-subtitle-1 mb-2 primary--text font-weight-normal">Enter phone number to refare friend</div>
          <v-textarea
            v-model="form.members"
            required placeholder="E.g 07011223344"
            outlined
            :rules="[rules.required]"
          >
          </v-textarea>
          <v-btn x-large block @click="$refs.invite.validate() ? inviteFriend() : null" class="primary">Submit</v-btn>
          <v-btn x-large block text class="mt-2" outlined @click="inviteDialog = false">Dismiss</v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="inviteDialogAgent" width="600px">
      <v-card class="pa-8 form-colored-bg">
        <div class="text-center">
          <h4>Invite Member to Agent Savings</h4>
          <small>Kindly complete the form below to add a member</small>
        </div>
        <v-form lazy-validation v-model="valid" ref="inviteagent" class="mt-10">
          <div class="text-h6 primary--text font-weight-normal">Member's Phone Number</div>
          <v-text-field
            v-model="inviteAgent.phone_number"
            outlined
            :rules="[rules.required]"
          >
          </v-text-field>
          <div class="text-h6 primary--text font-weight-normal">Periodic Savings Amount</div>
          <v-text-field
            v-model="inviteAgent.amount"
            outlined
            :rules="[rules.required]"
          >
          </v-text-field>
          <div class="text-h6 primary--text font-weight-normal">Target Amount</div>
          <v-text-field
            v-model="inviteAgent.overall"
            :rules="[rules.required]"
            outlined
          >
          </v-text-field>
          <div class="text-h6 primary--text font-weight-normal">Duration</div>
          <v-text-field
            v-model="inviteAgent.duration"
            :rules="[rules.required]"
            outlined
          >
          </v-text-field>
          <div class="text-h6 primary--text font-weight-normal">Cycle</div>
          <v-select
            placeholder="Select"
            required
            v-model="inviteAgent.cycle"
            :rules="[rules.required]"
            :items="['Daily', 'Weekly', 'Monthly']"
            outlined
          ></v-select>
          <div class="text-h6 primary--text font-weight-normal">Set Start Date</div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                append-icon="event_note"
                outlined
                :value="inviteAgent.start_date"
                placeholder="Select start date"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              light
              v-model="inviteAgent.start_date"
              @change="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <div class="text-h6 primary--text font-weight-normal">Set Withdrawal /End Date</div>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                append-icon="event_note"
                outlined
                :value="inviteAgent.end_date"
                placeholder="Select withdrawal date"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              light
              v-model="inviteAgent.end_date"
              @change="menu = false"
            ></v-date-picker>
          </v-menu>
          <div class="text-h6 primary--text font-weight-normal">Description</div>
          <v-textarea
            v-model="inviteAgent.description"
            outlined
            :rules="[rules.required]"
          ></v-textarea>
          <v-btn
            class="primary"
            block
            :loading="loading"
            large
            text
            @click="inviteAgentMember()"
            >Invite Member</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectUser" width="500">
      <v-card class="form-colored-bg">
        <div class="pa-8 px-5 px-md-10 primary">
          <v-row>
            <v-col cols="12" md="5" class="text-center">
              <v-avatar size="100">
                <img alt="" width="100" :src="selectedUser.image || require('@/assets/icons/user_big.png')"/>
              </v-avatar>
              
            </v-col>
            <v-col cols="12" md="7" class="text-center text-md-left">
              <div class="text-h5 font-weight-medium white--text mt-md-3">{{ selectedUser.name | capitalize }}</div>
              <div class="text-caption font-weight-medium secondary--text mt-2">{{ selectedInfo.name | capitalize }}</div>
              <div class="font-weight-medium white--text"><span class="text-caption">Savings Balance:</span> <span class="text-h6">&#8358; {{ selectedUser.balance }}</span></div>
            </v-col>
          </v-row>
        </div>
        <div class="mb-5 pa-6 px-5 px-md-10 ">
          <v-row>
            <v-col class="text-center">
              <v-card dark color="white" class="pa-2 primary--text">
                <div class="secondary--text text-caption font-weight-medium">Total</div>
                <div class="text-caption text-center font-weight-medium">Invite friend</div>
              </v-card>
            </v-col>
            <v-col class="text-center">
              <v-card dark color="white" class="pa-2 primary--text">
                <div class="secondary--text text-caption font-weight-medium">Status</div>
                <div class="text-caption text-center font-weight-medium">Completed</div>
              </v-card>
            </v-col>
            <v-col class="text-center">
              <v-card dark color="white" class="pa-2 primary--text">
                <div class="secondary--text text-caption font-weight-medium">Position</div>
                <div class="text-caption text-center font-weight-medium"><span v-show="selectedUser.admin == 1">Admin</span></div>
              </v-card>
            </v-col>
          </v-row>
          <div class="mt-5">
            <v-tabs>
              <v-tabs-slider color="secondary"></v-tabs-slider>
              <v-tab>Details</v-tab>
              <v-tab>History</v-tab>
            
              <v-tab-item>
                <v-container class="py-10 my-10 text-center">
                  <div class="text--disabled text-subtitle-2">No data to display</div>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container class="py-10 my-10 text-center">
                  <div class="text--disabled text-subtitle-2">No data to display</div>
                </v-container>
              </v-tab-item>
              </v-tabs>
          </div>
          <v-btn block class="mt-2" depressed large @click="selectUser = !selectUser">Dismiss</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import CreatePersonalSavings from '@/components/savings/CreatePersonalSavings.vue'
import CreateGroupSavings from '@/components/savings/CreateGroupSavings'
import CreateAgentSavings from '@/components/savings/CreateAgentSavings'
import paystack from 'vue-paystack'
import MiniLoader from '@/components/MiniLoader.vue'
import axios from "axios"

import { mapGetters } from 'vuex'
export default {
  components: {
    CreatePersonalSavings,
    CreateGroupSavings,
    paystack,
    CreateAgentSavings,
    MiniLoader
  },
  layout: 'dashboard',
  transition: 'default',
  data() {
    return {
      createNew: '',
      topUpDialog: false,
      inviteDialog: false,
      inviteDialogAgent: false,
      loading: false,
      valid: true,
      rules: {
        required: value => !!value || 'This field is required',
        min: value => value >= 10 || "Transfer amount can't go below ₦10",
        ext_min: value => value >= 100 || "Savings amount can't go below ₦100"
      },
      menu: false,
      menu1: false,
      activeAccount: '',
      reference: '',
      paystackkey: process.env.VUE_APP_ENV_PAYSTACK_PUBLIC_KEY,
      wallet: '',
      form: {},
      inviteAgent: {},
      personalSavings: '',
      publicGroupSavings: '',
      agentSavings: '',
      privategroupSavings: '',
      form: {},
      extendsavings: false,
      transfersavings: false,
      settings: false,
      quitsavings: false,
      groupmembers: false,
      agentgroupmembers: false,
      selectedGroupMembers: [],
      selectedSavingsHistory: [],
      joinrequests: false,
      agentjoinrequests: false,
      groupvote: false,
      showSavings: 'Personal',
      savingsmembers: [],
      grouprequests: [],
      tovote: false,
      selectUser: false,
      selectedUser: [],
      commission: false,
      votemember: '',
      p: [],
      g: [],
      a: [],
      searchedMember: false,
      s: [],
      personalIsLoading: true,
      privategroupIsLoading: true,
      publicgroupIsLoading: true,
      agentIsLoading: true,
      selectedSavingsId: this.$route.params.id,
      selectedInfo: [],
      days_left: '',
      savings_progress: 0,
      today: new Date().toISOString().slice(0,10),
      disbursementOutcome: [],
      groupHistory: false,
      headers: [
        {
          text: 'Transaction Details',
          align: 'start',
          value: 'type'
        },
        {
          text: 'Date',
          value: 'date_deposited'
        },
        {
          text: 'Amount',
          value:  'amount'
        }
      ]
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    overallAmount: function () {
      const a_month = 31 // days
      const duration = this.form.duration * a_month
      // get amount to save 
      if (this.form.cycle == 'Daily') {
        const amt = Math.round((this.form.amount / duration) * 1)
        return amt.toFixed(2)
      } else if (this.form.cycle == 'Weekly') {
        const amt = Math.round((this.form.amount / duration) * 7)
        return amt.toFixed(2) 
      } else if (this.form.cycle == 'Monthly') {
        const amt = Math.round((this.form.amount / duration) * 31)
        return amt.toFixed(2) 
      }
    },
  },

  filters: {
    capitalize: function (value) {
        if (!value) return ''
            value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },

  methods: {
    getDateDiff() {
      var date1 = new Date()
      var date2 = new Date(this.selectedInfo.end_date)
      var difference_in_time  = date2.getTime() - date1.getTime()
      var difference_in_days = difference_in_time / (1000 * 3600 * 24)
      if(Math.round(difference_in_days) < 0) {
        this.days_left = 0
      } else {
        this.days_left = Math.round(difference_in_days)
      }
    },
    getProgress() {
      var date1 = new Date(this.selectedInfo.start_save)
      var date2 = new Date(this.selectedInfo.end_date)
      var difference_in_time  = date2.getTime() - date1.getTime()
      var difference_in_days = difference_in_time / (1000 * 3600 * 24)
      var completed = Math.round(difference_in_days)
      var progress = completed - this.days_left
      var progress_percent = (progress * 100) / completed
      this.savings_progress = progress_percent
      //this.savings_progress = Math.round(difference_in_days)
    },
    async getSelectedSavings() {
      const data = {
        id: this.selectedSavingsId
      }
      try {
        let res = await this.$store.dispatch("savings/getselected", data)
        this.selectedInfo = res.account
        this.getDateDiff()
        this.getProgress()
        var year = this.today.getFullYear()
        var month = this.today.getMonth()
        var date = this.today.getDate()
        if (date < 10) {
          date = '0' + date;
        }
        if (month < 10) {
          month = '0' + month;
        }
        this.today = year + "-" + month + "-" + date
      } catch (err) {
        
      }
    },
    loadSpinner() {
      setTimeout(() => {
        this.selectandLoad = false
      }, 1000)
    },
    createReference() {
      let text = ''
      let possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))

      this.reference = `TS_WEB_${text}`
    },
    callback: function (response) {
      this.topUpSavingsCard()
    },
    close: function () {
      this.createReference()
    },
    async getWalletBalance() {
      const data = {
        user_id: this.user.id,
      };
      await this.$store
        .dispatch("auth/wallet", data, { root: true })
        .then((response) => {
          if (response == 0) {
            this.wallet = "0";
          } else {
            this.wallet = response;
          }
        });
    },
    async getLoanBalance() {
      const data = {
        user_id: this.user.id,
      };
      await this.$store
        .dispatch("auth/loanbalance", data, { root: true })
        .then((response) => {
          this.loan_balance = response;
        });
    },
    async withdrawSavings() {
      this.loading = true
      const data = {
        account_id: this.selectedSavingsId,
        user_id: this.user.id,
        transaction_pin: this.form.transaction_pin
      }
      try {
        let res = await this.$store.dispatch("savings/withdrawpersonalsavings", data)
        this.$toast.success(res.message)
        this.loading = false
        this.$refs.withdrawal.reset()
      } catch(err) {
        this.loading = false
        this.$toast.error(err.response.data.message)
      }
    },
    async joinGroup(group_id) {
      const data = {
        user_id: this.user.id,
        group_id: group_id
      }
      try{
        let res = await this.$store.dispatch('savings/joinpublicgroup', data)
        this.$toast.error(res.message)
      } catch(err) {
        this.$toast.error(err.response.data.message)
      }
    },
    showTopUp() {
      if(this.selectedInfo.type == 'Personal' || this.selectedInfo.type == 'Fixed') {
        return true
      } else if(this.selectedInfo.type == 'Group' && this.searchedMember) {
        return true
      } else if(this.selectedInfo.type == 'Agent' && this.searchedMember) {
        return true
      }
    },
    async getDisbursementVoteOutcome() {
      const data = {
        id: this.selectedSavingsId
      }
      try {
        let res = await this.$store.dispatch("savings/getDisbursementVoteOutcome", data)
        this.disbursementOutcome = res.votes
      } catch (err) {
        
      }
    },
    async joinAgent(agent_group_id) {
      const data = {
        user_id: this.user.id,
        group_id: agent_group_id
      }
      try{
        let res = await this.$store.dispatch('savings/joinagentgroup', data)
        this.$toast.success(res.message)
      } catch(err) {
        this.$toast.error(err.response.data.message)
      }
    },
    async getGroupMembers() {
      const data = {
        groupId: this.selectedSavingsId
      }
      try {
        let res = await this.$store.dispatch('savings/allgroupmembers', data)
        this.selectedGroupMembers = res.members
        this.selectandLoad = false
        this.s = Object.values(this.selectedGroupMembers)
        this.s = this.s.map(({member_id}) => ({member_id}))
        this.s = this.s.map(({ member_id }) => member_id)
        this.s = this.s.find((member_id) => member_id == this.user.id)
        if(this.s == undefined) {
          this.searchedMember = false
        } else{
          this.searchedMember = true
        }
      } catch (err) {

      }
    },
    async getAgentGroupMembers(agent_group_id) {
      const data = {
        groupId: agent_group_id
      }
      try {
        let res = await this.$store.dispatch('savings/allagentgroupmembers', data)
        this.selectedGroupMembers = res.members
        this.selectandLoad = false
        this.a = Object.values(this.selectedGroupMembers)
        this.a = this.a.map(({member_id}) => ({member_id}))
        this.a = this.a.map(({ member_id }) => member_id)
        this.a = this.a.find((member_id) => member_id == this.user.id)
        if(this.s == undefined) {
          this.searchedMember = false
        } else {
          this.searchedMember = true
        }
      } catch (err) {

      }
    },
    async getGroupSavingsHistory() {
      this.loading = true
      const data = {
        groupId: this.selectedInfo.id
      }
      try {
        let res = await this.$store.dispatch('savings/savingshistory', data)
        this.selectedSavingsHistory = res.history
        this.loading = false
      } catch (err) {

      }
    },
    async getAgentSavingsHistory(agent_group_id) {
      const data = {
        groupId: agent_group_id
      }
      try {
        let res = await this.$store.dispatch('savings/agentsavingshistory', data)
        this.selectedSavingsHistory = res.history
      } catch (err) {

      }
    },
    clickPayStack() {
      document.getElementById('paystack').click()
    },
    async topUpSavingsCard() {
      this.loading = true
      if(this.selectedInfo.type == "Personal") {
        const data = {
          payment_ref: this.reference,
          account_id: this.selectedInfo.id,
          user_id: this.user.id,
          amount: this.selectedInfo.projected_saving,
          save_card: 1,
        }
        var res_p = this.$store.dispatch('savings/fundpersonalsavingswithcard', data)
      } else if(this.selectedInfo.type == "Group") {
        const data = {
          payment_ref: this.reference,
          account_id: this.selectedInfo.id,
          user_id: this.user.id,
          amount: this.selectedInfo.projected_saving,
          save_card: 1,
        }
        var res_p = this.$store.dispatch('savings/fundgroupsavingswithwallet', data)
      }
      try {
        let res = await res_p
        this.$toast.success(res.message)
        this.loading = this.topUpDialog = false
        this.getSelectedSavings()
        this.$refs.topup.reset()
        this.createReference()
      } catch(error) {
        this.$toast.error(error.response.data.message)
        this.loading = false
      }
    },
    async topUpSavingsWallet() {
      this.loading = true
      if(this.selectedInfo.type == "Personal") {
        const data = {
          account_id: this.selectedInfo.id,
          user_id: this.user.id,
          amount: this.selectedInfo.projected_saving
        }
        var res_p = this.$store.dispatch('savings/fundpersonalsavingswithwallet', data)
      } else if(this.selectedInfo.type == "Group") {
        const data = {
          account_id: this.selectedInfo.id,
          user_id: this.user.id,
          amount: this.selectedInfo.projected_saving
        }
        var res_p = this.$store.dispatch('savings/fundgroupsavingswithwallet', data)
      }
      try {
        let res = await res_p
        this.$toast.success(res.message)
          this.loading = this.topUpDialog = false
          this.getSelectedSavings()
          this.$refs.topup.reset()
      } catch(error) {
        this.$toast.error(error.response.data.message)
        this.loading = false
      }
    },
    async topUpAgentCard() {
      this.loading = true
      const data = {
        payment_ref: this.reference,
        account_id: this.activeAccount.id,
        agent_id: this.activeAccount.userId,
        user_id: this.user.id,
        amount: this.form.amount,
        save_card: 1,
      }
      await this.$store
        .dispatch('savings/fundagentsavingswithcard', data)
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = this.topUpDialog = false
          this.getPersonalSavings()
          this.createReference()
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
          this.loading = false
        })
    },
    async topUpGroupWallet() {
      this.loading = true
      const data = {
        account_id: this.activeAccount.id,
        user_id: this.user.id,
        amount: this.form.amount,
      }
    },
    async topUpAgentWallet(account_id,agent_id) {
      this.loading = true
      const data = {
        account_id: account_id,
        agent_id: agent_id,
        user_id: this.user.id,
        amount: this.form.amount,
      }
      await this.$store
        .dispatch('savings/fundagentsavingswithwallet', data)
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = this.topUpDialog = false
          this.getPersonalSavings()
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
          this.loading = false
        })
    },
    async inviteFriend() {
      this.loading = true
      const data = {
        group_id: this.selectedSavings.id,
        admin_id: this.user.id,
        phone_number: this.form.members,
      }
      await this.$store
        .dispatch('savings/invitefriendgroup', data)
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = false
          this.form.members = ''
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
          this.loading = false
        })
    },
    async inviteAgentMember() {
      this.loading = true
      await this.$store
        .dispatch('savings/inviteagentmember', this.inviteAgent)
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = false
          this.inviteDialogAgent = false
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message)
          this.loading = false
        })
    },
    async extendSavings() {
      this.loading = true 
      this.form.overall_amount = this.overallAmount
      this.form.account_id = this.selectedInfo.id
      if( this.selectedInfo.type == "Personal" ) {
        var res_p = this.$store.dispatch('savings/extendpersonalsavings', this.form)
      }
      try {
        let res = await res_p
        this.$toast.success(res.message)
        this.loading = false
        this.extendsavings = false
        this.getSelectedSavings()
        this.$refs.extend.reset()
      } catch(error) {
        this.$toast.error(error.response.data.message)
        this.loading = false
      }
    },
    async quittarget() {
      this.loading = true
      this.form.account_id = this.selectedInfo.id
      if( this.selectedInfo.type == "Personal" ) {
        var res_p = this.$store.dispatch('savings/quitpersonalsavings', this.form)
      }
      try {
        let res = await res_p
        this.$toast.success(res.message)
        this.loading = false
        this.extendsavings = false
        this.$refs.quit.reset()
        this.$router.push('/dashboard/savings')
      } catch(error) {
        this.$toast.error(error.response.data.message)
        this.loading = false
      }
    },
    async voteAdmin() {
      this.loading = true
      const data = {
        user_id: this.user.id,
        account_id: this.selectedInfo.id,
        disburse_to: this.votemember,
        disburse: 1,
      }
      try {
        let res = await this.$store.dispatch('savings/voteadmin', data)
        this.$toast.success(res.message)
        this.loading = false
        this.tovote = ''
        this.$refs.disburse.reset()
      } catch (err) {
        this.$toast.error(err.response.data.message)
        this.loading = false
      }
    },
    async voteDisburse() {
      this.loading = true
      const data = {
        user_id: this.user.id,
        account_id: this.selectedInfo.id,
        disburse_to: this.votemember,
        disburse: 1,
      }
      try {
        let res = await this.$store.dispatch('savings/votedisburse', data)
        this.$toast.success(res.message)
        this.loading = false
        this.tovote = ''
        this.$refs.disburse.reset()
      } catch (err) {
        this.$toast.error(err.response.data.message)
        this.loading = false
      }
    },

    async getGroupRequests() {
      const data = {
        group_id: this.selectedSavingsId
      }
      try {
        let res = await this.$store.dispatch('savings/getgrouprequests', data)
        this.grouprequests = res.requests
      } catch (err) {

      }
    },
    async getAgentRequests(groupId) {
      const data = {
        group_id: groupId
      }
      try {
        let res = await this.$store.dispatch('savings/getagentrequests', data)
        this.grouprequests = res.requests
      } catch (err) {

      }
    },
    async accept(user_id, group_id, id ) {
      const data = {
        group_id: group_id,
        user_id: user_id,
        admin_id: this.user.id,
        accept_request: "1"
      }
      try {
        let res = await this.$store.dispatch('savings/acceptgrouprequest', data)
        this.$toast.success(res.message)
        this.grouprequests.splice(this.grouprequests.indexOf(id), 1)
      } catch (err) {
        this.$toast.error(err.response.data.message)
      }
    },
    async reject(user_id, group_id, id) {
      const data = {
        group_id: group_id,
        user_id: user_id,
        admin_id: this.user.id,
        accept_request: "0"
      }
      try {
        let res = await this.$store.dispatch('savings/acceptgrouprequest', data)
        this.$toast.success(res.message)
        this.grouprequests.splice(this.grouprequests.indexOf(id), 1)
      } catch (err) {
        this.$toast.error(err.response.data.message)
      }
    },
    async acceptagentjoin(user_id, group_id, id ) {
      const data = {
        group_id: group_id,
        user_id: user_id,
        agent_id: this.user.id,
        accept_request: "1"
      }
      try {
        let res = await this.$store.dispatch('savings/acceptagentgrouprequest', data)
        this.$toast.success(res.message)
        this.grouprequests.splice(this.grouprequests.indexOf(id), 1)
      } catch (err) {
        this.$toast.error(err.response.data.message)
      }
    },
    async rejectagentjoin(user_id, group_id, id) {
      const data = {
        group_id: group_id,
        user_id: user_id,
        agent_id: this.user.id,
        accept_request: "0"
      }
      try {
        let res = await this.$store.dispatch('savings/acceptagentgrouprequest', data)
        this.$toast.success(res.message)
        this.grouprequests.splice(this.grouprequests.indexOf(id), 1)
      } catch (err) {
        this.$toast.error(err.response.data.message)
      }
    }
  },

  mounted() {
    this.getWalletBalance()
    this.createReference()
    this.getSelectedSavings()
    this.getGroupMembers()
    this.getGroupRequests()
    this.form.user_id = this.user.id
    /*const options = {
      method: 'GET',
      url: 'https://wft-geo-db.p.rapidapi.com/v1/geo/cities',
      headers: {
        'x-rapidapi-key': '3aac6f58bamsh971cb0f7f9af120p168f06jsn13ee3aee59a1',
        'x-rapidapi-host': 'wft-geo-db.p.rapidapi.com'
      }
    };

    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });*/
  },
}
</script>
<style lang="scss" scoped>
.next-card {
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
  }
}
.single-savings-card {
  border: 0.5px solid #d9dbfa;
  box-shadow: 0px 2px 2px rgba(171, 153, 153, 0.25) !important;
  box-sizing: border-box;
  border-radius: 5px;
  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 132.5%;
    color: #c1a13d;
  }
  &:hover {
    background: #ffffff;
    border: 0.5px solid #484dc7;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px 9px rgba(219, 200, 200, 0.25);
    border-radius: 5px;
  }
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 105.5%;
    color: #2f2d2d;
    span {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 132.5%;
      color: #171b70;
    }
  }
  .v-card {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 132.5%;
    color: #171b70;
  }
}
.v-progress-linear {
  border-radius: 10px !important;
}
.vote_ {
  border: 1px solid #DDDDDD;
}
.days-left {
  height: 48px;
  width: 48px;
  border-radius: 40px;
  background: #c4c4c4;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 132.5%;
  /* or 21px */

  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
}
.v-tabs-slider {
  background-color: #c1a13d
}
.actin_cursr {
  cursor: pointer;
}
.outl {
  background-color: #EEEEEE;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.avatars {
  transform: scale(-1, 1);
  display: inline-block;
}
.avatar {
  position: relative;
  display: inline-block;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.avatar:not(:first-child) {
  margin-left: -10px;
}
.avatar img {
  display: block;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px !important
}
.loading {
  height: calc(100vh);
  position: absolute;
  display: block;
  width: 100%;
  top: 40%;
}
</style>
